import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Card, Columns } from 'react-bulma-components'

// Components
import ArrowLink from './link-with-arrow'

function ResourceCard(props) {
  return props.jumbo ? (
    <Card className={`classy has-text-left has-shadow ${props.className}`}>
      <span class="circle is-bottom-left" />
      <Columns className="content is-vcentered">
        <Columns.Column className="is-5-widescreen is-6-desktop is-12-mobile is-hidden-mobile">
          <Link
            to={`/resources/${props.resource.slug}`}
            className="book-container"
          >
            <div class="book">
              {props.resource.featuredMedia.gatsbyImageData ? (
                <img
                  className="gatsby-image-wrapper"
                  src={
                    props.resource.previewPageNumber > 0
                      ? `${props.resource.file.url}?page=${props.resource.previewPageNumber}&fm=jpg&q=80&w=500&h=281&fit=crop`
                      : props.resource.featuredMedia.url
                  }
                  alt={
                    props.resource?.featuredMedia?.alt === '' || 'PocketWorks'
                  }
                />
              ) : (
                <img
                  src="https://www.datocms-assets.com/20955/1579120771-dscf7651fotor.jpg?fm=jpg&w=1280&h=960&fit=clip"
                  alt="Placeholder"
                />
              )}
            </div>
          </Link>
        </Columns.Column>
        <Columns.Column className="is-6-widescreen is-5-desktop is-12-mobile ml-5-widescreen ml-5-desktop">
          <h3 className="is-size-5 has-font-weight-300">FEATURED</h3>
          <Link to={`/resources/${props.resource.slug}`} className="pt-0">
            <h2 className="title mt-0">{props.resource.name}</h2>
          </Link>
          <p
            className="is-size-4"
            dangerouslySetInnerHTML={{ __html: props.resource.description }}
          />
          <p className="is-pulled-left">
            <Link
              className="has-text-dark is-size-5"
              to={`/resources/${props.resource.slug}`}
            >
              View details →
            </Link>
          </p>
        </Columns.Column>
      </Columns>
    </Card>
  ) : (
    <Link to={`/resources/${props.resource.slug}`}>
      <Card className="classy is-equal-height is-rounded has-bg-white">
        <span
          className={Math.floor(Math.random() * 10) < 5 ? 'square' : 'circle'}
        />
        <div className="">
          <div className="">
            <img
              className="has-shadow"
              src={
                props.resource.previewPageNumber > 0
                  ? `${props.resource.file.url}?page=${props.resource.previewPageNumber}&fm=jpg&q=80&w=500&h=281&fit=crop&crop=bottom`
                  : `${props.resource.featuredMedia.url}&fm=jpg&q=80&w=500&h=281&fit=crop&crop=bottom`
              }
              alt={props.resource?.featuredMedia?.alt === '' || 'PocketWorks'}
            />
          </div>
        </div>

        <Card.Content className="p-5">
          <h3 className="title mt-0 mb-1">
            <Link to={`/resources/${props.resource.slug}`} className="title">
              {props.resource.name}
            </Link>
          </h3>
          <p className="is-uppercase is-size-7 mb-2 has-text-grey">
            <span
              className="is-size-6"
              dangerouslySetInnerHTML={{
                __html: props.resource.price
                  ? props.resource.price
                  : 'FREE Resource',
              }}
            />
          </p>
          <p
            className="is-size-6"
            dangerouslySetInnerHTML={{ __html: props.resource.description }}
          />
        </Card.Content>
      </Card>
    </Link>
  )
}

ResourceCard.propTypes = {
  jumbo: PropTypes.bool,
  resource: PropTypes.object,
  className: PropTypes.string,
}

export default ResourceCard
