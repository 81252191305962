import PropTypes from 'prop-types'
import React from 'react'
import DownloadResource from '../../../../../components/download-resource'

function Content({ data }) {
  return (
    <section>
      <div class="container px-5 pt-24 pb-16 mx-auto">
        <div class="grid gap-10 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 items-center">
          <div>
            <h2 class="text-[40px] text-black leading-[48px] font-semibold w-full mb-6">
              Even a badly drawn picture can say a thousand words.
            </h2>
            <ul class="list-disc list-inside leading-[34px] text-[20px] text-[#4B4B4B] p-0 mr-24 mb-5">
              <li class="p-0 m-0">
                We've made this for you because we want to{' '}
                <span class="font-semibold">
                  help you avoid the big mistakes.
                </span>
              </li>
              <li class="p-0 m-0">
                We've pulled together our{' '}
                <span class="font-semibold">collective knowledge</span> spanning
                dozens of apps reaching millions of users.
              </li>
              <li class="p-0 m-0">
                Use this when you first{' '}
                <span class="font-semibold">
                  start planning your mobile app
                </span>
                , or as you approach a new release.
              </li>
              <li class="p-0 m-0">
                It's designed to be{' '}
                <span class="font-semibold">good food-for-thought</span>, to{' '}
                <span class="font-semibold">stimulate talking points</span> with
                you and the other folks involved in your app.
              </li>
              <li class="p-0 m-0">
                <span class="font-semibold">
                  Download now and launch a winner, it's FREE.
                </span>
              </li>
            </ul>
            <DownloadResource
              id="download-form-2"
              url={data.resource.file.url}
              slug={data.resource.slug}
              price={data.resource.price}
              priceValue={data.resource.priceValue}
              payhipProductId={data.resource.payhipProductId}
            />
          </div>
          <div class="bg-white rounded-[20px] p-8 w-full mt-10">
            <p class="font-semibold text-[20px] text-[#4B4B4B] mb-4">
              Contents
            </p>
            <ol class="list-decimal list-inside text-[18px] text-[#4B4B4B] leading-[30px]">
              <li>Your MVP is too big</li>
              <li>Bling doesn't pay the bills</li>
              <li>The subscription app death slide</li>
              <li>Voice of customer</li>
              <li>Pareto's law for apps</li>
              <li>Essential push campaigns</li>
              <li>Money at the low end</li>
              <li>Hidden app costs</li>
              <li>Good disco, bad disco</li>
              <li>The cost of running ads</li>
              <li>Why orgs build apps</li>
              <li>Waterfall feels so right, even when it isn't</li>
              <li>Useful data for app growth</li>
              <li>Solve together</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  )
}
Content.propTypes = {
  data: PropTypes.object,
}

export default Content
