import { StaticImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { Columns } from 'react-bulma-components'
import { hydrate } from 'react-dom'

function DownloadResource({
  id,
  slug,
  url,
  price,
  priceValue,
  payhipProductId,
}) {
  const formRef = useRef(null)

  const toggleForm = () => {
    formRef.current.classList.toggle('is-active')
  }

  const buyNow = () => {
    // eslint-disable-next-line no-undef
    // Payhip.Checkout.open({
    //   product: payhipProductId,
    //   message: 'A custom message to add to the checkout',
    // })
    window.location = `https://payhip.com/buy?link=${payhipProductId}`
  }

  const handleSubmit = (e) => {
    console.log('Submitting')
    e.preventDefault()
    const myForm = document.getElementById(id)
    const formData = new FormData(myForm)
    // document.querySelector('#form-submit-button').innerText =
    //   'Please wait a few seconds...'
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        console.log('Form successfully submitted')
        // document.querySelector('#form-submit-button').innerText =
        //   'Please wait a few seconds...'
        // const pdfUrl = document.querySelector('#article-pdf').value
        // window.open(url, '_blank').focus()
        toggleForm()
        if (typeof window !== 'undefined' && window.gtag) {
          window.gtag('event', `resource_downloaded_${slug}`, { slug })
        }
        if (window.hj) {
          window.hj('event', 'resource_downloaded')
          window.hj('event', `resource_downloaded_${slug}`)
        }
        window.location = url
      })
      .catch((error) => alert(error))
  }

  return (
    <div className="no-print has-text-left">
      {priceValue > 0 ? (
        <a
          role="button"
          onClick={buyNow}
          tabIndex={0}
          onKeyDown={() => {}}
          className="mr-2 button is-leafy is-medium is-primary is-size-6 is-leafy"
        >
          Buy now
        </a>
      ) : (
        <span />
      )}
      <a
        className={
          priceValue > 0
            ? 'button ml-2 is-size-6 is-leafy is-secondary is-medium mb-3'
            : 'button ml-2 is-size-6 is-leafy is-medium is-primary mb-3'
        }
        id="download-pdf-link"
        onClick={toggleForm}
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
      >
        {priceValue > 0 ? 'Download Sample' : 'Download Now'}
      </a>

      <div
        className="modal branded-form"
        id="modal-resource-download-box"
        ref={formRef}
      >
        <div className="modal-background" />
        <div className="modal-card">
          <form
            name="download-resource"
            id={id}
            // method="POST"
            onSubmit={handleSubmit}
            data-netlify="true"
            netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="download-resource" />
            <input type="hidden" name="resource" value={`${slug}`} />
            <input type="hidden" id="file" name="file" value={`${url}`} />
            <header className="modal-card-head p-0">
              <button
                type="button"
                className="button cancel-button is-text p-5 has-text-danger has-text-weight-bold"
                onClick={toggleForm}
              >
                Cancel
              </button>
            </header>
            <section className="modal-card-body">
              <div class="has-text-centered">
                <StaticImage
                  src="https://www.datocms-assets.com/20955/1710859562-appy-wave.png?w=200"
                  className="pt-0"
                />
                <h3 className="title pb-3 mt-3 has-font-weight-bold">
                  Free Download
                </h3>
              </div>
              <div className="field">
                <label className="label is-hidden">Your first name*</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="name"
                    placeholder="Your first name*"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label is-hidden">Your work email*</label>
                <div className="control">
                  <input
                    className="input"
                    type="email"
                    name="email"
                    placeholder="Your work email*"
                    required
                  />
                </div>
              </div>
              <Columns className="is-hidden">
                <Columns.Column>
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        name="role"
                        placeholder="Job role"
                      />
                    </div>
                  </div>
                </Columns.Column>
                <Columns.Column>
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        name="industry"
                        placeholder="Industry"
                      />
                    </div>
                  </div>
                </Columns.Column>
              </Columns>
              <button
                className="button is-primary is-leafy is-fullwidth mt-5"
                id="form-submit-button"
                type="submit"
              >
                {priceValue > 0 ? 'Download Sample' : 'Download Now'}
              </button>
              <button
                type="button"
                className="button cancel-button is-hidden"
                onClick={toggleForm}
              >
                Cancel
              </button>
              <p className="pt-3 is-size-6 has-text-centered">
                We'll email you with updates no more than twice a month.
                <br />
                You can unsubscribe at any time.
              </p>
            </section>
          </form>
        </div>
      </div>
    </div>
  )
}

DownloadResource.propTypes = {
  id: PropTypes.number,
  price: PropTypes.string,
  payhipProductId: PropTypes.string,
  slug: PropTypes.string,
  url: PropTypes.string,
}

export default DownloadResource
