/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'

// Meta Component
import Meta from 'components/Meta'

// Resource Route
import Resource from 'routes/templates/resource'

// Gatsby SEO Head
export function Head({ data }) {
  return (
    <Meta tags={data.resource.seoMetaTags.tags} hideMeta hideTitle>
      <title>{`${data.resource.name} - Pocketworks Mobile Tools and Resources`}</title>
      <meta name="description" content={data.resource.description} />
    </Meta>
  )
}

export default Resource

export const query = graphql`
  query ResourceQuery($slug: String!) {
    resource: datoCmsResource(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      name
      description
      isGated
      price
      priceValue
      payhipProductId
      previewPages
      blurbNode {
        childMarkdownRemark {
          html
        }
      }
      previewPageNumber
      previewPageNumber2
      howToUseHeading
      howToUseNode {
        childMarkdownRemark {
          html
        }
      }
      slug
      file {
        url
        fixed(
          height: 200
          imgixParams: { h: "200", fm: "jpg", auto: "compress" }
        ) {
          ...GatsbyDatoCmsFixed
        }
      }
      heroImage {
        url
        gatsbyImageData(
          placeholder: TRACED_SVG
          imgixParams: { fm: "png", auto: "compress", w: "600px" }
        )
        fluid(
          maxWidth: 600
          imgixParams: {
            fit: "crop"
            crop: "bottom"
            w: "1080"
            h: "900"
            fm: "jpg"
            auto: "compress"
          }
        ) {
          ...GatsbyDatoCmsFluid_tracedSVG
        }
      }

      featuredMedia {
        url
        fluid(
          maxWidth: 600
          imgixParams: {
            fit: "crop"
            crop: "bottom"
            w: "1080"
            h: "900"
            fm: "jpg"
            auto: "compress"
          }
        ) {
          ...GatsbyDatoCmsFluid_tracedSVG
        }
      }
    }
    resources: allDatoCmsResource(
      filter: { isHidden: { eq: false } }
      limit: 3
      sort: { fields: position }
    ) {
      edges {
        node {
          id
          name
          isFeatured
          slug
          previewPageNumber
          description
          isHidden
          price
          payhipProductId
          file {
            url
            fixed(
              height: 200
              imgixParams: {
                h: "200"
                fm: "jpg"
                fit: "crop"
                auto: "compress"
              }
            ) {
              ...GatsbyDatoCmsFixed
            }
          }
          featuredMedia {
            url
            gatsbyImageData(
              placeholder: TRACED_SVG
              imgixParams: {
                w: "640"
                h: "480"
                fit: "crop"
                fm: "jpg"
                auto: "compress"
              }
            )
          }
        }
      }
    }
  }
`
