import PropTypes from 'prop-types'
import React from 'react'
import { Columns } from 'react-bulma-components'

// Components
import ArrowLink from 'components/link-with-arrow'
import DownloadResource from '../../../../../components/download-resource'

function TechCard({ data }) {
  return (
    <section class="bg-white">
      <div class="container px-5 pt-20 pb-12 mx-auto">
        <div class="flex flex-col text-center w-full mb-20">
          <h2 class="text-[40px] text-[#4B4B4B] mb-4">
            Reduce costs and increase your app revenues
          </h2>
          <p class="text-[20px] text-black leading-[24px]">
            Here's what you'll get when downloading this free Resource:
          </p>
        </div>
        <div class="flex flex-wrap -m-4 text-center mb-5">
          <div class="p-4 md:w-1/4 sm:w-1/2 w-full ">
            <div class="bg-[#F6F6F6] px-4 pt-4 pb-6 rounded-[20px] min-h-[320px]">
              <img
                class="rounded w-full object-contain object-center mb-6 h-[124px]"
                src="https://www.datocms-assets.com/20955/1721038236-2019_pocketworks_illustrations_02.png"
                alt="14 illustrated topics to help your app"
              />
              <p class="text-[20px] text-[#4B4B4B]">
                14 illustrated topics to help your app
              </p>
            </div>
          </div>
          <div class="p-4 md:w-1/4 sm:w-1/2 w-full ">
            <div class="bg-[#F6F6F6] px-4 pt-4 pb-6 rounded-[20px] min-h-[320px]">
              <img
                class="rounded w-full object-contain object-center mb-6 h-[124px]"
                src="https://www.datocms-assets.com/20955/1721038239-opera_senza_titolo-58.png"
                alt="apps with millions of users"
              />
              <p class="text-[20px] text-[#4B4B4B] leading-[24px]">
                Insights gained from working on apps with millions of users
              </p>
            </div>
          </div>
          <div class="p-4 md:w-1/4 sm:w-1/2 w-full ">
            <div class="bg-[#F6F6F6] px-4 pt-4 pb-6 rounded-[20px] min-h-[320px]">
              <img
                class="rounded w-full object-contain object-center mb-6 h-[124px]"
                src="https://www.datocms-assets.com/20955/1721038243-opera_senza_titolo-60.png"
                alt="planning, design, quality and marketing"
              />
              <p class="text-[20px] text-[#4B4B4B] leading-[24px]">
                Topics ranging from planning, design, quality and marketing
              </p>
            </div>
          </div>
          <div class="p-4 md:w-1/4 sm:w-1/2 w-full ">
            <div class="bg-[#F6F6F6] px-4 pt-4 pb-6 rounded-[20px] min-h-[320px]">
              <img
                class="rounded w-full object-contain object-center mb-6 h-[124px]"
                src="https://www.datocms-assets.com/20955/1579120467-speedupillustrationpocketworks.png"
                alt="2024 version with four new diagrams"
              />
              <p class="text-[20px] text-[#4B4B4B] leading-[24px]">
                Updated 2024 version with four new diagrams
              </p>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-center">
          <DownloadResource
            id="download-form-2"
            url={data.resource.file.url}
            slug={data.resource.slug}
            price={data.resource.price}
            priceValue={data.resource.priceValue}
            payhipProductId={data.resource.payhipProductId}
          />
        </div>
      </div>
    </section>
  )
}
TechCard.propTypes = {
  data: PropTypes.object,
}
export default TechCard
