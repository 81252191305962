import React from 'react'

function Testimonial() {
  return (
    <section>
      <div class="container px-5 pt-28 pb-20 mx-auto">
        <div class="flex flex-wrap -m-4 justify-center">
          <div class="relative p-4 md:w-1/2 w-full">
            <div class="h-full bg-white p-8 rounded-[20px]">
              <div class="absolute left-1/2 transform -translate-x-1/2 -top-10 w-[100px] h-[100px]">
                <img
                  class="rounded-full border-white w-[100px] h-[100px] object-cover object-center"
                  src="https://www.datocms-assets.com/20955/1721038357-ellipse-2.png"
                  alt="Avatar"
                />
              </div>
              <div class="w-full flex justify-center mt-5 mb-5">
                <img
                  class=" border-white  object-contain object-center h-[35px] mb-0"
                  src="https://www.datocms-assets.com/20955/1721038683-5starrating.png"
                  alt="5 Stars"
                />
              </div>
              <p class="leading-[24px] italic mb-5 text-center">
                "PocketWorks' Badly Drawn Mobile series is the best example I've
                seen of clearly and eloquently explaining the how, why and what
                of the mobile dev process."
              </p>
              <div class="flex justify-between items-center px-6">
                <a class="inline-flex items-center">
                  <span class="flex-grow flex flex-col pl-4 text-[20px] text-black leading-[24px]">
                    <span class="font-bold">Tom Riglar,</span>
                    <span>Managing Director</span>
                  </span>
                </a>
                <img
                  class=" border-white  object-contain object-center h-[27px] mb-0"
                  src="https://www.datocms-assets.com/20955/1721038685-moropo-logo.png"
                  alt="moropo logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Testimonial
