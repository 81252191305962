import PropTypes from 'prop-types'
import React from 'react'
import DownloadResource from '../../../../../components/download-resource'

function BloodyHard({ data }) {
  return (
    <section class="bg-white">
      <div class="container px-5 pt-20 pb-12 mx-auto">
        <div class="flex flex-col text-center mb-10 items-center justify-center">
          <h2 class="text-[40px] text-[#4B4B4B] leading-[48px] font-semibold w-full md:w-1/2 mb-6">
            Apps are exciting to develop, but they're also bloody hard!
          </h2>
          <p class="text-[18px] text-black leading-[21px] md:w-5/12">
            Working with brands such as ASDA, Travelodge and Carbs & Cals, we've
            seen a lot of what works and what doesn't.
            <br />
            <br />
            This short guide contains seven easy illustrations that help you
            launch a more effective app.
          </p>
        </div>
        <div class="flex flex-wrap -m-4 text-center mb-5">
          <img
            src="https://www.datocms-assets.com/20955/1721039240-badly-drawn-mobile-screenshots.png"
            alt="Badly Drawn Mobile Screenshots"
            class="flex justify-center"
          />
        </div>
        <div class="flex items-center justify-center">
          <DownloadResource
            id="download-form-2"
            url={data.resource.file.url}
            slug={data.resource.slug}
            price={data.resource.price}
            priceValue={data.resource.priceValue}
            payhipProductId={data.resource.payhipProductId}
          />
        </div>
      </div>
    </section>
  )
}
BloodyHard.propTypes = {
  data: PropTypes.object,
}

export default BloodyHard
