import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import { Container, Columns, Hero } from 'react-bulma-components'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { BookJsonLd } from 'gatsby-plugin-next-seo'
// Components
import Separator from 'components/separator'
import AskQuestion from 'components/ask-question'
import { GatsbyImage } from 'gatsby-plugin-image'
import DownloadResourceHero from '../../../../../components/download-resource-hero'
import SiteNav from '../../../../../components/navbar'

function ResourceHero({ data }) {
  const heroImageUrl = () => {
    if (data.resource.previewPageNumber > 0) {
      return `${data.resource.file.url}?page=${data.resource.previewPageNumber}&fm=jpg&q=80&w=1200`
    }
    if (data.resource.heroImage) {
      return data.resource.heroImage.url
    }
    return data.resource.featuredMedia.url
  }
  return (
    <Hero className="is-small has-background-grey-lighter">
      <Hero.Header>
        <SiteNav fullLogo />
      </Hero.Header>
      <Hero.Body className="mt-6 pt-5 mb-6">
        <Container className="content">
          <Columns className="is-vcentered">
            <Columns.Column className="is-6 is-vcentered">
              <h2 className="subtitle is-size-5 has-text-uppercase">
                {data.resource.priceValue > 0 ? 'PRODUCT' : 'FREE DOWNLOAD'}
              </h2>
              <h1 className="title is-size-2 has-text-weight-bold">
                {data.resource.name}
              </h1>
              <BookJsonLd
                author={{
                  '@type': 'Organization',
                  name: 'Pocketworks Mobile Ltd',
                }}
                url={`https://pocketworks.co.uk/resources/${data.resource.slug}`}
                name={data.resource.name}
                publisher={{
                  '@type': 'Organization',
                  name: 'Pocketworks Mobile Ltd',
                  location: 'United Kingdom',
                }}
              />
              {data.resource.price ? (
                <div
                  className="is-size-5 mb-5 "
                  dangerouslySetInnerHTML={{
                    __html: data.resource.price,
                  }}
                />
              ) : (
                <span />
              )}
              <div
                className="is-size-4 pb-6"
                dangerouslySetInnerHTML={{
                  __html: data.resource.description
                    ? data.resource.description
                    : '',
                }}
              />
              {data.resource.isGated ? (
                <DownloadResourceHero
                  id="download-form-1"
                  url={data.resource.file.url}
                  slug={data.resource.slug}
                  price={data.resource.price}
                  priceValue={data.resource.priceValue}
                  payhipProductId={data.resource.payhipProductId}
                />
              ) : (
                <OutboundLink
                  className="button is-primary is-leafy is-medium"
                  href={data.resource.file.url}
                >
                  Free Download
                </OutboundLink>
              )}
              <div
                className="content mt-6 is-hidden"
                dangerouslySetInnerHTML={{
                  __html: data.resource.blurbNode
                    ? data.resource.blurbNode.childMarkdownRemark.html
                    : '',
                }}
              />
            </Columns.Column>
            <Columns.Column className="is-6 pl-6">
              {data.resource.heroImage ? (
                <GatsbyImage image={data.resource.heroImage.gatsbyImageData} />
              ) : (
                <div className="perspective-1 p-2 has-background-white">
                  <img
                    className=""
                    src={heroImageUrl()}
                    alt={data.resource.name}
                  />
                </div>
              )}
            </Columns.Column>
          </Columns>
        </Container>
      </Hero.Body>
    </Hero>
  )
}
ResourceHero.propTypes = { data: PropTypes.object }
export default ResourceHero
