import PropTypes from 'prop-types'
import React from 'react'
import { Columns } from 'react-bulma-components'
import { StaticImage } from 'gatsby-plugin-image'
import DownloadResource from '../../../../../components/download-resource'

// page Components
import Navigation from '../navigation'

function HowToUse({ data }) {
  return (
    <div>
      <Columns className="is-vcentered">
        <Columns.Column className="is-6-desktop">
          <div className="p-6 mr-6 is-hidden">
            <img
              className="perspective-2"
              src={
                data.resource.previewPageNumber2 > 0
                  ? `${data.resource.file.url}?page=${data.resource.previewPageNumber2}&fm=jpg&q=80&w=1200`
                  : ''
              }
              alt=""
            />
          </div>
          <div className="classy card has-bg-white mr-6 ">
            <span
              className={
                Math.floor(Math.random() * 10) < 5
                  ? 'square is-bottom-left'
                  : 'circle is-bottom-right'
              }
            />
            <Columns className="is-centered is-vcentered is-mobile">
              {data.resource.previewPages ? (
                JSON.parse(data.resource.previewPages).map((page) => (
                  <Columns.Column
                    className={
                      JSON.parse(data.resource.previewPages).length > 9
                        ? 'is-3-desktop is-4-mobile'
                        : 'is-4-desktop is-4-mobile'
                    }
                  >
                    <img
                      className="perspective-2"
                      src={`${data.resource.file.url}?page=${page.number}&fm=jpg&q=80&w=300`}
                      alt=""
                    />
                  </Columns.Column>
                ))
              ) : (
                <Columns.Column className="is-8">
                  <StaticImage src="https://www.datocms-assets.com/20955/1710859562-appy-wave.png?w=800" />
                </Columns.Column>
              )}
            </Columns>
          </div>
        </Columns.Column>
        <Columns.Column className="is-6-desktop">
          <h3 className="title">What's included?</h3>
          <div
            className="content mt-3"
            dangerouslySetInnerHTML={{
              __html: data.resource.blurbNode
                ? data.resource.blurbNode.childMarkdownRemark.html
                : '',
            }}
          />
        </Columns.Column>
      </Columns>
      <hr />
      <Columns>
        <Columns.Column className="is-offset-2-desktop is-4-desktop pt-6">
          <h3 className="title has-text-weight-bold">
            {data.resource.howToUseHeading}
          </h3>
        </Columns.Column>
        <Columns.Column className="is-6-desktop pt-6">
          <div
            dangerouslySetInnerHTML={{
              __html: data.resource.howToUseNode.childMarkdownRemark.html,
            }}
          />
          <br />
          <DownloadResource
            id="download-form-2"
            url={data.resource.file.url}
            slug={data.resource.slug}
            price={data.resource.price}
            priceValue={data.resource.priceValue}
            payhipProductId={data.resource.payhipProductId}
          />
        </Columns.Column>
      </Columns>
    </div>
  )
}
HowToUse.propTypes = {
  data: PropTypes.object,
}
export default HowToUse
