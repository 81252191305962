import { Link } from 'gatsby'
import React from 'react'

function OurServices() {
  return (
    <section>
      <div class="container px-5 pt-28 pb-0 mx-auto">
        <div class="flex flex-col justify-center text-center mb-6">
          <div class="text-center mx-auto">
            <h2 class="text-[40px] text-[#4B4B4B] mb-4 md:w-1/3 mx-auto  leading-[48px]">
              Your growth-focused mobile partner.
            </h2>
            <p class="text-[18px] text-black leading-[22px] md:w-1/2 mx-auto ">
              We believe that every sustainable business deserves to thrive in a
              mobile-first world. Founded in 2012, we're a diverse team of
              nineteen digital product experts across five European countries,
              with headquarters in Leeds, UK.
            </p>
          </div>
        </div>
        <div class="flex items-center justify-center w-full">
          <Link
            to="/services"
            class="button is-leafy is-medium is-primary h-[72px] w-[238px]"
          >
            Our Services
          </Link>
        </div>
        <img
          class="h-44 w-full object-contain object-center"
          src="https://www.datocms-assets.com/20955/1721052407-pocketworks-team-sand.png"
          alt="Pocketworks team"
        />
      </div>
    </section>
  )
}

export default OurServices
