import PropTypes from 'prop-types'
import React from 'react'
import { Columns } from 'react-bulma-components'

// Components
import ArrowLink from 'components/link-with-arrow'
import ResourceCard from 'components/resource-card'
import { Link } from 'gatsby'

function OurResources({ resources }) {
  return (
    <section class="bg-white">
      <div class="container px-5 pt-20 pb-12 mx-auto">
        <div class="flex flex-col text-center w-full mb-20">
          <h2 class="text-[40px] text-[#4B4B4B] font-semibold mb-4">
            More about our Resources
          </h2>
          <p class="text-[18px] text-black leading-[24px]">
            If you found Badly Drawn Mobile interesting,
            <br /> we're sure you're gonna like these ones too:
          </p>
        </div>
        <div class="flex flex-wrap -m-4 mb-6">
          {resources.edges.map(({ node: resource }) => (
            <Columns.Column
              className="is-4-desktop is-6-tablet"
              key={resource.slug}
            >
              <ResourceCard jumbo={false} resource={resource} />
            </Columns.Column>
          ))}
        </div>
        <div class="flex items-center justify-center">
          <Link
            to="/mobile-strategy-toolkit/"
            class="button is-leafy is-medium is-primary h-[72px]"
          >
            View all resources
          </Link>
        </div>
      </div>
    </section>
  )
}
OurResources.propTypes = {
  resources: PropTypes.object,
}
export default OurResources
