import PropTypes from 'prop-types'
import React from 'react'
import { Section, Container, Columns } from 'react-bulma-components'

// Components
import SiteNav from 'components/navbar'
import Layout from 'components/layout'

// Page Components
import ResourceHero from './components/resource-hero'
import BookWorkshop from './components/book-workshop'
import HowToUse from './components/how-to-use/HowToUse'
import TechCard from './components/tech-card/TechCard'
import Testimonial from './components/testimonial/Testimonial'
import BloodyHard from './components/bloody-hard/BloodyHard'
import Content from './components/content/Content'
import OurResources from './components/our-resources/OurResources'
import OurServices from './components/our-services/OurServices'

function Resource({ data }) {
  return (
    <Layout>
      <ResourceHero data={data} />
      {data.resource.slug === 'badly-drawn-mobile' ? (
        <div>
          <TechCard data={data} />
          <Testimonial />
          <BloodyHard data={data} />
          <Content data={data} />
          <OurResources resources={data.resources} />
          <OurServices />
        </div>
      ) : (
        <Section className="mt-6">
          <Container className="content">
            <HowToUse data={data} />
          </Container>
        </Section>
      )}
    </Layout>
  )
}
Resource.propTypes = {
  data: PropTypes.object,
}
export default Resource
